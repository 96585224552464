

























































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import moment from "moment";
import { Dictionary } from "lodash";
import { api as activityApi, ActivityDoc } from "@/store/modules/activities";
import { api as userApi, Getters as UserGetters } from "@/store/modules/user";

@Component({})
export default class AuditActivityStream extends Vue {
  loading = false;
  get boxes(): Array<{
    id: string;
    upper_bound: string;
    lower_bound: string;
    description: string;
  }> {
    const now = new Date();

    console.log(
      now.toISOString(),
      moment(now).endOf("day").toISOString(),
      moment(now).startOf("day").toISOString()
    );

    return [
      {
        id: "today",
        upper_bound: moment(now).endOf("day").toISOString(),
        lower_bound: moment(now).startOf("day").toISOString(),
        description: this.$t(
          "components.widgets.sidebar.audit_activity_stream.today"
        ).toString(),
      },
      {
        id: "yesterday",
        upper_bound: moment(now).subtract(1, "day").endOf("day").toISOString(),
        lower_bound: moment(now)
          .subtract(1, "day")
          .startOf("day")
          .toISOString(),
        description: this.$t(
          "components.widgets.sidebar.audit_activity_stream.yesterday"
        ).toString(),
      },
      {
        id: "lastweek",
        upper_bound: moment(now).subtract(2, "day").endOf("day").toISOString(),

        lower_bound: moment(now)
          .subtract(7, "day")
          .startOf("day")
          .toISOString(),
        description: this.$t(
          "components.widgets.sidebar.audit_activity_stream.last_week"
        ).toString(),
      },
      {
        id: "older",
        upper_bound: moment(now).subtract(8, "day").endOf("day").toISOString(),
        lower_bound: moment("2000-01-01").startOf("day").toISOString(),
        description: this.$t(
          "components.widgets.sidebar.audit_activity_stream.older"
        ).toString(),
      },
    ];
  }

  @Getter(activityApi.getters.getSortedActivityList, {
    namespace: activityApi.namespace,
  })
  sortedActivities!: ActivityDoc[];

  @Getter(activityApi.getters.gethasMoreActivities, {
    namespace: activityApi.namespace,
  })
  hasMoreData!: boolean;

  @Getter(userApi.getters.getActivityTimeStamp, {
    namespace: userApi.namespace,
  })
  activityTimestamp!: UserGetters["getActivityTimeStamp"];

  @Action(activityApi.actions.loadMoreActivities, {
    namespace: activityApi.namespace,
  })
  loadMoreActivities!: () => Promise<number>;

  @Action(activityApi.actions.markActivitiesAsRead, {
    namespace: activityApi.namespace,
  })
  markActivitiesAsRead!: () => Promise<string>;

  getDisplayActivity(item: any) {
    if (item.verb === "create") {
      return this.$t(
        "components.widgets.sidebar.audit_activity_stream.template_created",
        {
          userName: item.actor_name,
          auditName: item.audit_name,
        }
      ).toString();
    } else if (item.verb === "update") {
      return this.$t(
        "components.widgets.sidebar.audit_activity_stream.template_edited",
        {
          userName: item.actor_name,
          auditName: item.audit_name,
        }
      ).toString();
    } else if (item.verb === "delete") {
      return this.$t(
        "components.widgets.sidebar.audit_activity_stream.template_deleted",
        {
          userName: item.actor_name,
          auditName: item.audit_name,
        }
      ).toString();
    } else {
      return item.verb;
    }
  }

  get boxedActivities(): Array<{
    id: string;
    description: string;
    items: ActivityDoc[];
  }> {
    const boxesMap = this.boxes
      .map(b => b.id)
      .reduce((p, c) => ({ ...p, [c]: [] }), {} as Dictionary<ActivityDoc[]>);

    const containingBox = (activity: ActivityDoc) => {
      const ts = moment(activity.timestamp);
      const box = this.boxes.find(b => {
        return ts.isBetween(b.lower_bound, b.upper_bound, undefined, "[]");
      });
      return box?.id ?? "older";
    };

    this.sortedActivities.forEach(activity => {
      boxesMap[containingBox(activity)].push(activity);
    });

    return this.boxes.map(b => {
      return {
        id: b.id,
        description: b.description,
        items: boxesMap[b.id],
      };
    });
  }
  isNew(activityTime): boolean {
    if (this.activityTimestamp === null) {
      return (
        new Date(activityTime) >
        new Date(moment().subtract(2, "weeks").startOf("day").toISOString())
      );
    } else {
      return new Date(activityTime) > new Date(this.activityTimestamp);
    }
  }

  hasTimePart(time: string): boolean {
    return time.indexOf("T") > 0;
  }

  load() {
    this.loading = true;

    this.loadMoreActivities()
      .then(count => {
        console.log("loadMoreActivities done", count);
        const actions = this.$refs["actions"] as Element;
        actions.scrollIntoView();
      })
      .catch(err => {
        console.error("loadMoreActivities failed", err);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  mounted() {
    document.documentElement.style.overflow = "hidden";
  }
  updated() {
    document.documentElement.style.overflow = "hidden";
  }
  beforeDestroy() {
    this.markActivitiesAsRead();
    document.documentElement.style.overflow = "visible";
  }
}
